import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // Import uuid
import './App.css';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [admin, setAdmin] = useState(localStorage.getItem('admin') === 'true');
  const [adminName, setAdminName] = useState(localStorage.getItem('admin_name') || '');
  const [chatgptActive, setChatgptActive] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [expandedImage, setExpandedImage] = useState(null);
  const [showSelectionScreen, setShowSelectionScreen] = useState(true); // State to manage the selection screen
  const ws = useRef(null);
  const inputRef = useRef(null); // Create a ref for the input field

  const topics = [
    { label: "Cybersecurity", prompt: "Hi! I have a question about cybersecurity." },
    { label: "Data Backup and Recovery", prompt: "Hi! I have a question about backing up data." },
    { label: "Wi-Fi Issues", prompt: "Hi! I'm having trouble with my Wi-Fi." },
    { label: "Email Problems", prompt: "Hi! I'm having a problem with my email." },
    { label: "Software Issue", prompt: "Hi! I'm having a problem with software." },
    { label: "Other", prompt: "" }
  ];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      localStorage.setItem('email', emailParam);
      setEmail(emailParam);
    } else if (!localStorage.getItem('email')) {
      const uuidEmail = uuidv4();
      localStorage.setItem('email', uuidEmail);
      setEmail(uuidEmail);
    }

    if (email) {
      axios.get(`/api/messages/${email}`)
        .then(response => {
          if (Array.isArray(response.data)) {
            if (response.data.length === 0) {
              setShowSelectionScreen(true); // Show selection screen if no messages
            } else {
              setMessages(response.data);
              setShowSelectionScreen(false); // Don't show selection screen if there are messages
            }
          } else {
            console.error('Error: Received data is not an array', response.data);
          }
        })
        .catch(error => {
          console.error('Error loading messages from server:', error);
        });

      axios.get(`/api/chatgpt_active/${email}`)
        .then(response => {
          if (response.data.chatgpt_active !== undefined) {
            setChatgptActive(response.data.chatgpt_active);
          } else {
            console.error('Error: chatgpt_active is undefined', response.data);
          }
        })
        .catch(error => {
          console.error('Error loading chatgpt_active from server:', error);
        });

      const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
      const wsUrl = `${wsProtocol}${window.location.host}/ws/${email}`;
      ws.current = new WebSocket(wsUrl);

      ws.current.onopen = () => {
        console.log('WebSocket connection established');
      };

      ws.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.chatgpt_active !== undefined) {
          setChatgptActive(data.chatgpt_active);
        }
        if (data.messages !== undefined) {
          setMessages(data.messages);
          setShowSelectionScreen(false); // Don't show selection screen if there are messages
        }
        setIsSending(false);
      };

      ws.current.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.current.onclose = (event) => {
        console.log('WebSocket connection closed:', event);
      };

      return () => {
        if (ws.current) {
          ws.current.close();
        }
      };
    }
  }, [email]);

  useEffect(() => {
    if (inputRef.current && !isSending) {
      inputRef.current.focus(); // Focus the input field when not sending
    }
  }, [isSending]);

  const handleSend = async (initialMessage) => {
    const messageToSend = initialMessage || input;
    if (messageToSend.trim()) {
      setIsSending(true);
      const userName = admin ? adminName : email;
      const newMessage = { text: messageToSend, user: userName, source: admin ? 'admin' : 'user', timestamp: new Date().toISOString() };
      const newMessages = [newMessage, ...messages];
      setMessages(newMessages);
      setInput('');

      if (!admin && chatgptActive) {
        const typingMessage = { text: '...', user: 'IT Support Bot', source: 'chatgpt', timestamp: new Date().toISOString() };
        setMessages([typingMessage, ...newMessages]);
      }

      try {
        if (!admin && chatgptActive) {
          const systemPrompt = "You're an IT support bot in Slack. You work for a company called Supple IT Co. which provides IT support to small to mid-sized industrial companies, such as manufacturers and engineering firms. Supple IT Co. will be inviting customers into Slack channels with you. You will provide them with IT support as needed. Please give specific instructions, and ask for more clarification when necessary. In addition, please be friendly and patient with our customers. Not all of them understand technology deeply. If anyone asks, you're Supple IT Co.'s support bot.";

          const nonImageMessages = newMessages.filter(message => !message.isImage).map(message => ({
            role: message.source === 'user' ? 'user' : 'assistant',
            content: message.text,
          }));

          const response = await axios.post('https://api.openai.com/v1/chat/completions', {
            model: 'gpt-4',
            messages: [
              { role: 'system', content: systemPrompt },
              ...nonImageMessages.reverse(),
            ],
          }, {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
            },
          });

          const gptMessage = response.data.choices[0].message.content;
          const updatedMessages = [{ text: gptMessage, user: 'IT Support Bot', source: 'chatgpt', timestamp: new Date().toISOString() }, ...newMessages];
          setMessages(updatedMessages);
          axios.post(`/api/messages`, { email, messages: updatedMessages })
            .then(() => {
              console.log('Updated messages saved to server for email:', email);
            })
            .catch(error => {
              console.error('Error saving messages to server:', error);
            });
        } else {
          axios.post(`/api/messages`, { email, messages: newMessages })
            .then(() => {
              console.log('Updated messages saved to server for email:', email);
            })
            .catch(error => {
              console.error('Error saving messages to server:', error);
            });
        }
      } catch (error) {
        console.error('Error calling OpenAI API:', error);
        setMessages(newMessages);
        setIsSending(false);
      }
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && email && !isSending) {
      handleSend();
    }
  };

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail);
    localStorage.setItem('email', newEmail);
    axios.get(`/api/messages/${newEmail}`)
      .then(response => {
        if (Array.isArray(response.data)) {
          setMessages(response.data);
          if (response.data.length > 0) {
            setShowSelectionScreen(false); // Don't show selection screen if there are messages
          }
        } else {
          console.error('Error: Received data is not an array', response.data);
        }
      })
      .catch(error => {
        console.error('Error loading messages from server:', error);
      });

    axios.get(`/api/chatgpt_active/${newEmail}`)
      .then(response => {
        if (response.data.chatgpt_active !== undefined) {
          setChatgptActive(response.data.chatgpt_active);
        } else {
          console.error('Error: chatgpt_active is undefined', response.data);
        }
      })
      .catch(error => {
        console.error('Error loading chatgpt_active from server:', error);
      });
  };

  const handleAdminToggle = () => {
    const newAdminState = !admin;
    setAdmin(newAdminState);
    localStorage.setItem('admin', newAdminState);
  };

  const handleChatgptToggle = () => {
    const newChatgptActiveState = !chatgptActive;
    setChatgptActive(newChatgptActiveState);
    axios.post(`/api/chatgpt_active`, { email, chatgpt_active: newChatgptActiveState })
      .then(() => {
        console.log('chatgpt_active state updated for email:', email);
      })
      .catch(error => {
        console.error('Error updating chatgpt_active state:', error);
      });
  };

  const handleEnterChat = () => {
    const systemMessage = { text: `${adminName} has entered the chat`, user: 'System', source: 'system', timestamp: new Date().toISOString() };
    setMessages([systemMessage, ...messages]);
    axios.post(`/api/messages`, { email, messages: [systemMessage, ...messages] })
      .then(() => {
        console.log('System message saved to server for email:', email);
      })
      .catch(error => {
        console.error('Error saving system message to server:', error);
      });
  };

  const handleLeaveChat = () => {
    const systemMessage = { text: `${adminName} has left the chat`, user: 'System', source: 'system', timestamp: new Date().toISOString() };
    setMessages([systemMessage, ...messages]);
    axios.post(`/api/messages`, { email, messages: [systemMessage, ...messages] })
      .then(() => {
        console.log('System message saved to server for email:', email);
      })
      .catch(error => {
        console.error('Error saving system message to server:', error);
      });
  };

  const send_message_to_slack = (email, text) => {
    axios.post(`/api/notify_human`, { email, message: text })
      .then(() => {
        console.log('Message sent to Slack');
      })
      .catch(error => {
        console.error('Error sending message to Slack:', error);
      });
  };

  const handleBannerClick = () => {
    if (!email) return;
    const systemMessage = { text: "Contacting a member of Supple IT Co. We'll do our best to get back to you ASAP, however, if a member of our team is not currently available we will email you back shortly. Thanks!", user: 'System', source: 'system', timestamp: new Date().toISOString() };
    setMessages([systemMessage, ...messages]);
    axios.post(`/api/messages`, { email, messages: [systemMessage, ...messages] })
      .then(() => {
        console.log('System message saved to server for email:', email);
      })
      .catch(error => {
        console.error('Error saving system message to server:', error);
      });
    send_message_to_slack(email, "is requesting to talk to a member of Supple IT Co.");
  };

  const handleTopicClick = (prompt) => {
    if (prompt) {
      handleSend(prompt);
    } else {
      const initialMessage = {
        text: "Hi there! I'm the Supple IT Co. Support Bot. I'm an AI model that has been fine-tuned on providing IT support to businesses like you. You can ask me questions about your Wi-Fi, computers, or business software. You can also rope in one of our expert humans by clicking on 'Talk to a Human'.",
        user: 'IT Support Bot',
        source: 'chatgpt',
        timestamp: new Date().toISOString()
      };
      setMessages([initialMessage]);
    }
    setShowSelectionScreen(false); // Hide the selection screen and show the chat screen
  };

  const containsList = (text) => {
    return /(\n\d+\.|\n- )/.test(text);
  };

  const handleImageUpload = async (event) => {
    if (!email) return;
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;
        const userName = admin ? adminName : email;
        const newMessage = { text: imageData, user: userName, source: admin ? 'admin' : 'user', timestamp: new Date().toISOString(), isImage: true };
        const newMessages = [newMessage, ...messages];
        setMessages(newMessages);

        axios.post(`/api/messages`, { email, messages: newMessages })
          .then(() => {
            console.log('Updated messages saved to server for email:', email);
          })
          .catch(error => {
            console.error('Error saving messages to server:', error);
          });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="App">
      <header className="header">
        <a href="https://suppleitco.com" target="_blank" rel="noopener noreferrer" className="header-title">
          <div>Supple IT Co.</div>
        </a>
        {!admin && !showSelectionScreen && (
          <button onClick={() => {
            if (!email) return;
            const systemMessage = { text: "Contacting a human to help! Hang tight, and we'll connect you with live support if one of our experts is available. Otherwise, we will reach out to you by email soon.", user: 'System', source: 'system', timestamp: new Date().toISOString() };
            setMessages([systemMessage, ...messages]);
            axios.post(`/api/messages`, { email, messages: [systemMessage, ...messages] })
              .then(() => {
                console.log('System message saved to server for email:', email);
              })
              .catch(error => {
                console.error('Error saving system message to server:', error);
              });
            send_message_to_slack(email, "is requesting human support");
          }}>Talk to a Human</button>
        )}
      </header>
      {showSelectionScreen ? (
        <div className="selection-screen">
          <h1>How can we help?</h1>
          <div className="topic-boxes">
            {topics.map((topic, index) => (
              <button key={index} className="topic-box" onClick={() => handleTopicClick(topic.prompt)}>
                {topic.label}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <>
          {!admin && (
            <div className="header-banner">
              Need IT support? Supple IT Co. can help! <span onClick={handleBannerClick}>Click here</span> to loop in a member of our team to discuss your needs.
            </div>
          )}
          {admin && (
            <div className="controls">
              <>
                <button onClick={handleChatgptToggle}>
                  {chatgptActive ? 'Disable IT Support Bot' : 'Enable IT Support Bot'}
                </button>
                <button onClick={handleAdminToggle}>Toggle Admin Mode</button>
                <button onClick={handleEnterChat}>Enter Chat</button>
                <button onClick={handleLeaveChat}>Leave Chat</button>
              </>
            </div>
          )}
          <div className="chat-container">
            <div className="messages">
              {messages.map((message, index) => (
                <div key={index} className={`message-container ${message.source}`}>
                  {message.source !== 'user' && message.source !== 'system' && <div className="message-label">{message.user}</div>}
                  {message.isImage ? (
                    <div className="image-container">
                      <img src={message.text} alt="uploaded" className={`message ${message.source === 'system' ? 'system-message' : ''} ${containsList(message.text) ? 'has-list' : ''}`} onClick={() => setExpandedImage(message.text)} />
                    </div>
                  ) : (
                    <div className={`message ${message.source === 'system' ? 'system-message' : ''} ${containsList(message.text) ? 'has-list' : ''}`}>
                      <ReactMarkdown className="markdown">{message.text}</ReactMarkdown>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="input-container">
              <input
                type="text"
                value={input}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Message Supple IT Co."
                disabled={!email || isSending}
                ref={inputRef} // Add ref to input field
              />
              <label htmlFor="file-upload" className={`image-upload-label ${!email ? 'disabled' : ''}`}>
                <FontAwesomeIcon icon={faImage} size="2x" color="grey" />
              </label>
              <input id="file-upload" type="file" accept="image/*" onChange={handleImageUpload} disabled={!email} />
              <button 
                onClick={handleSend} 
                disabled={!email || isSending}
                className={isSending || !email ? "disabled-button" : ""}
              >
                Send
              </button>
            </div>
          </div>
          {expandedImage && (
            <div className="image-modal" onClick={() => setExpandedImage(null)}>
              <img src={expandedImage} alt="expanded" className="expanded" />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default App;
